import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { pageContext } from '../../../../types'
import SEO from '~/utils/seo'
import { TimesPage } from '~/components/pages/TimesPage'

export default function OnsenTimesCategoryPage({ location, data, pageContext }: PageProps<GatsbyTypes.OnsenTimesCategoryPageQuery, pageContext & {categoryUrl: string, category: string}>) {
    return (
        <>
            <SEO
                title={`${pageContext.category}のニュース・記事 | Onsen*`}
                description='Onsen*のサービスや製品に関する最新情報やメディア掲載情報、イベント情報やお風呂にまつわる記事など多種多様なニュースを掲載しています。'
                robots='all'
                image={data?.allMicrocmsNews.edges[0]?.node?.thumbnail?.url}
                url={location.href}
            />
            <TimesPage data={data} pageContext={pageContext} pathName={`/times/${pageContext.categoryUrl}`} tagName={pageContext.category} />
        </>
    )
}

export const query = graphql`
	query OnsenTimesCategoryPage($category: String!, $skip: Int!, $limit: Int!) {
		allMicrocmsNews(filter: {category: {eq: $category}}, skip: $skip, limit: $limit, sort: {fields: postDate, order: DESC}) {
              edges {
                  node {
                      title
                      contents
                      newsId
                      thumbnail {
                          url
                      }
                      publishedAt(formatString: "YYYY.MM.DD")
                      postDate(formatString: "YYYY.MM.DD")
                  }
              }
        }
	}
`
